import { Layout, Menu } from 'antd'
import { useLocation } from 'react-router-dom'
import classnames from 'classnames'
import React from 'react'
import { Link } from 'react-router-dom'
import { useGlobalContext } from '../../context/globalContext'

import styles from './Layout.module.scss'

const { Header, Content, Footer } = Layout

interface Props {
  className?: string
  children: React.ReactNode
}

export default function BaseLayout({ className, children }: Props) {
  const { menu } = useGlobalContext()
  const location = useLocation()

  if (location.pathname === '/login') {
    return <>{children}</>
  }

  return (
    <Layout>
      <Header>
        <a href="/" className="h-full flex items-center float-left mr-10">
          <div className={styles.logo} />
          <div className={styles['logo-text']} />
        </a>
        {menu.length ? (
          <div className="max-w-6xl mx-auto">
            <Menu theme="dark" mode="horizontal">
              {menu.map((item, index) => (
                <Menu.Item key={index}>
                  <Link to={item.link} className="flex items-center">
                    {item.icon ? (
                      <span className="mr-1 flex items-center">
                        {item.icon}
                      </span>
                    ) : null}
                    {item.name}
                  </Link>
                </Menu.Item>
              ))}
            </Menu>
          </div>
        ) : null}
      </Header>
      <Content className={classnames(styles.main, className)}>
        {children}
      </Content>
      <Footer style={{ textAlign: 'center' }}>
        ©2021 北京灵河佳新科技有限公司
      </Footer>
    </Layout>
  )
}
