import dayjs from 'dayjs'

export const TOKEN_NO = 200000

// 监控文档地址
export const MONITOR_DOCUMENT_LINK =
  'https://www.yuque.com/lingheit/techdoc/qoglqp'

export enum MonitorType {
  GLOBAL_ERROR = 'globalError',
  RESOURCE_ERROR = 'resourceError',
  HTTP_ERROR = 'httpError',

  VISIT = 'visit',
}

export const MONITORING_TYPE_LABEL = {
  [MonitorType.GLOBAL_ERROR]: 'js报错',
  [MonitorType.RESOURCE_ERROR]: '静态资源报错',
  [MonitorType.HTTP_ERROR]: '接口请求报错',

  [MonitorType.VISIT]: '',
}

export const MONITORING_TYPE_LIST = [
  {
    value: MonitorType.GLOBAL_ERROR,
    label: MONITORING_TYPE_LABEL[MonitorType.GLOBAL_ERROR],
  },
  {
    value: MonitorType.RESOURCE_ERROR,
    label: MONITORING_TYPE_LABEL[MonitorType.RESOURCE_ERROR],
  },
  {
    value: MonitorType.HTTP_ERROR,
    label: MONITORING_TYPE_LABEL[MonitorType.HTTP_ERROR],
  },
]

export enum TimeSelectType {
  TODAY = 'TODAY',
  YESTERDAY = 'YESTERDAY',
  WEEK = 'WEEK',
  MONTH = 'MONTH',
}

export const TIME_SELECT_TYPE_LABEL = {
  [TimeSelectType.TODAY]: '今天',
  [TimeSelectType.YESTERDAY]: '昨天',
  [TimeSelectType.WEEK]: '近一周',
  [TimeSelectType.MONTH]: '近一月',
}

export const TIME_SELECT_TYPE_LIST = [
  {
    value: TimeSelectType.TODAY,
    label: TIME_SELECT_TYPE_LABEL[TimeSelectType.TODAY],
  },
  {
    value: TimeSelectType.YESTERDAY,
    label: TIME_SELECT_TYPE_LABEL[TimeSelectType.YESTERDAY],
  },
  {
    value: TimeSelectType.WEEK,
    label: TIME_SELECT_TYPE_LABEL[TimeSelectType.WEEK],
  },
  {
    value: TimeSelectType.MONTH,
    label: TIME_SELECT_TYPE_LABEL[TimeSelectType.MONTH],
  },
]
const TODAY_STR = dayjs().format('YYYYMMDD')

export type TimeRangeType = [string, string]

export const INIT_TIME_RANGE: TimeRangeType = [TODAY_STR, TODAY_STR]

export const TIME_SELECT_RANGE_LIST: {
  label: string
  timeRange: TimeRangeType
}[] = [
  {
    label: '今天',
    timeRange: [TODAY_STR, TODAY_STR],
  },
  {
    label: '昨天',
    timeRange: [dayjs().subtract(1, 'day').format('YYYYMMDD'), TODAY_STR],
  },
  {
    label: '近一周',
    timeRange: [dayjs().subtract(7, 'day').format('YYYYMMDD'), TODAY_STR],
  },
  {
    label: '近一月',
    timeRange: [dayjs().subtract(1, 'month').format('YYYYMMDD'), TODAY_STR],
  },
]

export enum IntervalType {
  MINUTE_10 = 'MINUTE_10',
  MINUTE_30 = 'MINUTE_30',
  MINUTE_60 = 'MINUTE_60',
  MINUTE_120 = 'MINUTE_120',
  MINUTE_360 = 'MINUTE_360',
  MINUTE_720 = 'MINUTE_720',
  MINUTE_1440 = 'MINUTE_1440',
}

export const INTERVAL_TYPE_LABEL = {
  [IntervalType.MINUTE_10]: '10分钟',
  [IntervalType.MINUTE_30]: '30分钟',
  [IntervalType.MINUTE_60]: '1小时',
  [IntervalType.MINUTE_120]: '2小时',
  [IntervalType.MINUTE_360]: '6小时',
  [IntervalType.MINUTE_720]: '12小时',
  [IntervalType.MINUTE_1440]: '24小时',
}

export const INTERVAL_TYPE_LIST = [
  {
    value: IntervalType.MINUTE_10,
    label: INTERVAL_TYPE_LABEL[IntervalType.MINUTE_10],
  },
  {
    value: IntervalType.MINUTE_30,
    label: INTERVAL_TYPE_LABEL[IntervalType.MINUTE_30],
  },
  {
    value: IntervalType.MINUTE_60,
    label: INTERVAL_TYPE_LABEL[IntervalType.MINUTE_60],
  },
  {
    value: IntervalType.MINUTE_120,
    label: INTERVAL_TYPE_LABEL[IntervalType.MINUTE_120],
  },
  {
    value: IntervalType.MINUTE_360,
    label: INTERVAL_TYPE_LABEL[IntervalType.MINUTE_360],
  },
  {
    value: IntervalType.MINUTE_720,
    label: INTERVAL_TYPE_LABEL[IntervalType.MINUTE_720],
  },
  {
    value: IntervalType.MINUTE_1440,
    label: INTERVAL_TYPE_LABEL[IntervalType.MINUTE_1440],
  },
]

export enum HomeDataType {
  PAGE = 'PAGE',
  SCREEN = 'SCREEN',
  VERSION = 'VERSION',
}

const HOME_DATA_TYPE_LABEL = {
  [HomeDataType.PAGE]: '页面访问数据',
  [HomeDataType.SCREEN]: '屏幕分辨率',
  [HomeDataType.VERSION]: '版本分布',
}

export const HOME_DATA_TYPE_LIST = [
  {
    value: HomeDataType.PAGE,
    label: HOME_DATA_TYPE_LABEL[HomeDataType.PAGE],
  },
  {
    value: HomeDataType.SCREEN,
    label: HOME_DATA_TYPE_LABEL[HomeDataType.SCREEN],
  },
  {
    value: HomeDataType.VERSION,
    label: HOME_DATA_TYPE_LABEL[HomeDataType.VERSION],
  },
]
