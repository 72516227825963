import { RouteConfig } from 'react-router-config'
import AsyncComponent from './AsyncComponent'

const routes: RouteConfig[] = [
  {
    path: '/',
    component: AsyncComponent(
      () => import(/* webpackChunkName: "home" */ '../page/Home')
    ),
    exact: true,
  },
  {
    path: '/login',
    component: AsyncComponent(
      () => import(/* webpackChunkName: "login" */ '../page/Login')
    ),
    exact: true,
  },
  {
    path: '/project/:appKey',
    component: AsyncComponent(
      () =>
        import(/* webpackChunkName: "project-detail" */ '../page/ProjectDetail')
    ),
    exact: true,
  },
  {
    path: '/project/:appKey/error',
    component: AsyncComponent(
      () =>
        import(/* webpackChunkName: "project-error" */ '../page/ProjectError')
    ),
    exact: true,
  },
  {
    path: '/project/:appKey/visit/user',
    component: AsyncComponent(
      () =>
        import(
          /* webpackChunkName: "project-visit-user" */ '../page/ProjectVisitUser'
        )
    ),
    exact: true,
  },
  {
    path: '/project/:appKey/visit/user/:visitorId',
    component: AsyncComponent(
      () =>
        import(
          /* webpackChunkName: "project-visit-user-info" */ '../page/ProjectVisitUserInfo'
        )
    ),
    exact: true,
  },
  {
    path: '/project/:appKey/script',
    component: AsyncComponent(
      () =>
        import(/* webpackChunkName: "project-script" */ '../page/ProjectScript')
    ),
    exact: true,
  },
]

export default routes
