import dayjs from 'dayjs'
import React from 'react'
import { TOKEN_NO } from '../constants'

function useAuth() {
  React.useEffect(() => {
    const { pathname } = window.location
    const token = window.localStorage.getItem('token')
    if (
      pathname !== '/login' &&
      (!token ||
        dayjs(Number(token) + TOKEN_NO).format('YYYYMMDD') !==
          dayjs().format('YYYYMMDD'))
    ) {
      window.location.replace('/login')
    }
  }, [])
}

export default useAuth
