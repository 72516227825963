import React, { lazy, Suspense } from 'react'
import { LoadingOutlined } from '@ant-design/icons'

const AsyncComponent = <T extends React.ComponentType<any>>(
  loader: () => Promise<{
    default: T
  }>
) => {
  const TargetComponent = lazy(loader)
  return (props: React.ComponentPropsWithRef<T>) => (
    <Suspense
      fallback={
        <div className="text-center">
          <LoadingOutlined className="text-blue-600" />
        </div>
      }
    >
      <TargetComponent {...props} />
    </Suspense>
  )
}

export default AsyncComponent
