import React from 'react'
import { ConfigProvider } from 'antd'
import dayjs from 'dayjs'
import ReactDOM from 'react-dom'
import './index.css'
import zhCN from 'antd/lib/locale/zh_CN'
import App from './App'
import reportWebVitals from './reportWebVitals'

import 'dayjs/locale/zh-cn'
dayjs.locale('zh-cn')

ReactDOM.render(
  <React.StrictMode>
    <ConfigProvider locale={zhCN}>
      <App />
    </ConfigProvider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
