import React from 'react'

interface Props {
  menu: RouteItem[]
  setMenu: React.Dispatch<React.SetStateAction<RouteItem[]>>
}

const GlobalContext = React.createContext<Props>({
  menu: [],
  setMenu: () => {},
})

export default GlobalContext

export function useGlobalContext() {
  return React.useContext(GlobalContext)
}
