import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import { renderRoutes } from 'react-router-config'

import routes from './router'
import GlobalContext from './context/globalContext'
import Layout from './components/Layout'
import useAuth from './hooks/useAuth'

function App() {
  const [menu, setMenu] = React.useState<RouteItem[]>([])

  useAuth()

  return (
    <GlobalContext.Provider value={{ menu, setMenu }}>
      <BrowserRouter>
        <Layout>{renderRoutes(routes)}</Layout>
      </BrowserRouter>
    </GlobalContext.Provider>
  )
}

export default App
